<script>
import { SuawMainContent, SuawInputGroup, SuawParagraph, SuawHeading, SuawLink } from "@suaw/suaw-component-library";
import ResourceFrame from "@/features/resources/components/ResourceFrame.vue";

export default {
  name: "Downloads",
  components: {
    SuawMainContent,
    SuawInputGroup,
    SuawParagraph,
    SuawHeading,
    SuawLink,
    ResourceFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ResourceFrame>
      <SuawInputGroup group-style="default" direction="column" group-gap="quadruple">
        <SuawHeading level="2" content="Downloads" />
        <SuawHeading level="3" content="Table Tent Printable" />
        <SuawParagraph
          text="Want to make sure Shut Up &amp; Write! members can find you at your local coffee shop? Download and print out this PDF, fold it in half, and set it up on a table at your in-person Shut Up &amp; Write!"
        />
        <SuawLink to="https://www.dropbox.com/s/k7c76kdmhd8wxne/SUAW%20Table%20Tent.pdf?dl=0" alignment="center" size="large">Download Table Tent PDF</SuawLink>
      </SuawInputGroup>
    </ResourceFrame>
  </SuawMainContent>
</template>
